var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CreateProductFirstStepMolecule', [_c('div', {
    staticClass: "cell name"
  }, [_c('ValidatableFieldMolecule', {
    attrs: {
      "validation": _vm.validations.product.name
    }
  }, [_c('EditableFieldMolecule', {
    attrs: {
      "label": 'Name',
      "value": _vm.validations.product.name.$model
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.validations.product.name, "$model", $event);
      }
    }
  })], 1)], 1), _c('div', {
    staticClass: "cell type"
  }, [_c('MultiselectFieldMolecule', {
    attrs: {
      "label": 'Product type*',
      "value": _vm.validations.product.type.$model,
      "options": _vm.productTypes.map(function (m) {
        return m.name;
      })
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.validations.product.type, "$model", $event);
      }
    }
  })], 1), _vm.validations.product.type.$model === 'APP' ? _c('div', {
    staticClass: "cell entry-point"
  }, [_c('ValidatableFieldMolecule', {
    attrs: {
      "validation": _vm.validations.product.entryPoint
    }
  }, [_c('EditableFieldMolecule', {
    attrs: {
      "label": 'Entry point',
      "value": _vm.validations.product.entryPoint.$model
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.validations.product.entryPoint, "$model", $event);
      }
    }
  })], 1)], 1) : _vm._e(), _c('ProductIconsMolecule', {
    staticClass: "cell product-icons",
    attrs: {
      "hasDarkIconOnly": _vm.validations.product.hasDarkIconOnly.$model,
      "iconDarkImageId": _vm.validations.product.iconDarkImageId.$model,
      "iconLightImageId": _vm.validations.product.iconLightImageId.$model
    },
    on: {
      "update:hasDarkIconOnly": function updateHasDarkIconOnly($event) {
        return _vm.$set(_vm.validations.product.hasDarkIconOnly, "$model", $event);
      },
      "update:has-dark-icon-only": function updateHasDarkIconOnly($event) {
        return _vm.$set(_vm.validations.product.hasDarkIconOnly, "$model", $event);
      },
      "update:iconDarkImageId": function updateIconDarkImageId($event) {
        return _vm.$set(_vm.validations.product.iconDarkImageId, "$model", $event);
      },
      "update:icon-dark-image-id": function updateIconDarkImageId($event) {
        return _vm.$set(_vm.validations.product.iconDarkImageId, "$model", $event);
      },
      "update:iconLightImageId": function updateIconLightImageId($event) {
        return _vm.$set(_vm.validations.product.iconLightImageId, "$model", $event);
      },
      "update:icon-light-image-id": function updateIconLightImageId($event) {
        return _vm.$set(_vm.validations.product.iconLightImageId, "$model", $event);
      }
    }
  }), _vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }