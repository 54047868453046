<template>
  <TalpaLoaderWrapper :transparent="true" v-if="this.$apollo.loading" />
  <ErrorAtom v-else-if="error" :error="error" />
  <CreateProductWizardOrganism v-else-if="availableLanguages.length > 0" :availableLanguages="availableLanguages" />
  <ErrorAtom v-else :error="new Error(`no languages defined`)" />
</template>

<script>
import { TalpaLoaderWrapper, ErrorAtom } from '@common/components'

import CreateProductWizardOrganism from './Organisms/CreateProductWizardOrganism'

import AVAILABLE_LANGUAGE_ENUMS_QUERY from '#/graphql/marketplace/availableLanguageEnumsQuery.gql'

export default {
  components: {
    TalpaLoaderWrapper,
    ErrorAtom,
    CreateProductWizardOrganism,
  },
  data() {
    return {
      error: undefined,
      availableLanguages: [],
    }
  },
  apollo: {
    availableLanguages: {
      query: AVAILABLE_LANGUAGE_ENUMS_QUERY,
      update: data => {
        return (data?.availableLanguageEnums?.enumValues ?? []).map(m => m.name)
      },
      error(err) {
        this.error = err
      },
    },
  },
}
</script>
