<template>
  <CreateProductWizardMolecule>
    <PanelMolecule>
      <span slot="header" class="title">Create Product</span>
      <slot slot="main" />
    </PanelMolecule>
  </CreateProductWizardMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import PanelMolecule from '@/components/Atomic/Molecules/PanelMolecule'

const CreateProductWizardMolecule = styled('div')`
  margin: 1rem;
  .wizard-icon {
    color: black;
  }
  .vue-form-wizard .wizard-nav-pills > li.active > a {
    color: black;
  }
  .vue-form-wizard .wizard-tab-content {
    padding: unset;
  }
`

export default {
  components: {
    PanelMolecule,
    CreateProductWizardMolecule,
  },
}
</script>
