<template>
  <CreateProductFirstStepMolecule>
    <div class="cell name">
      <ValidatableFieldMolecule :validation="validations.product.name">
        <EditableFieldMolecule :label="'Name'" :value.sync="validations.product.name.$model" />
      </ValidatableFieldMolecule>
    </div>
    <div class="cell type">
      <MultiselectFieldMolecule
        :label="'Product type*'"
        :value.sync="validations.product.type.$model"
        :options="productTypes.map(m => m.name)"
      />
    </div>
    <div v-if="validations.product.type.$model === 'APP'" class="cell entry-point">
      <ValidatableFieldMolecule :validation="validations.product.entryPoint">
        <EditableFieldMolecule :label="'Entry point'" :value.sync="validations.product.entryPoint.$model" />
      </ValidatableFieldMolecule>
    </div>
    <ProductIconsMolecule
      class="cell product-icons"
      :hasDarkIconOnly.sync="validations.product.hasDarkIconOnly.$model"
      :iconDarkImageId.sync="validations.product.iconDarkImageId.$model"
      :iconLightImageId.sync="validations.product.iconLightImageId.$model"
    />
    <slot />
  </CreateProductFirstStepMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import EditableFieldMolecule from '@/components/Atomic/Molecules/EditableFieldMolecule'
import ValidatableFieldMolecule from '@/components/Atomic/Molecules/ValidatableFieldMolecule'
import MultiselectFieldMolecule from '@/components/Atomic/Molecules/MultiselectFieldMolecule'
import ProductIconsMolecule from './ProductIconsMolecule'

const CreateProductFirstStepMolecule = styled('div')`
  padding: 3rem 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
  grid-gap: 2em;
  > .cell {
  }

  .product-icons,
  > .translation-editor-molecule {
    grid-column: span 2;
  }
`

export default {
  props: {
    validations: {
      type: Object,
      required: true,
    },
    productTypes: {
      type: Array,
      required: true,
    },
  },
  components: {
    CreateProductFirstStepMolecule,
    ValidatableFieldMolecule,
    EditableFieldMolecule,
    MultiselectFieldMolecule,
    ProductIconsMolecule,
  },
}
</script>
