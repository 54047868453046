var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CreateProductThirdStepMoleculeStyled', [_c('strong', [_vm._v("You will create the following product:")]), _c('div', {
    staticClass: "details"
  }, [_c('div', {
    staticClass: "type"
  }, [_vm._v("Type: " + _vm._s(_vm.product.type))]), _c('div', {
    staticClass: "name"
  }, [_vm._v("Name: " + _vm._s(_vm.product.name))]), _vm.product.type === 'APP' ? _c('div', {
    staticClass: "entry-point"
  }, [_vm._v("Entry point: " + _vm._s(_vm.product.entryPoint))]) : _vm._e()]), _c('strong', [_vm._v("with the following tier levels:")]), _vm._l(_vm.product.tierLevels, function (tierLevel, i) {
    return _c('div', {
      key: tierLevel.id,
      staticClass: "tier-level"
    }, [_c('div', [_c('strong', [_vm._v("Tier level " + _vm._s(tierLevel.tierLevel))])]), _c('div', [_c('span', [_vm._v("that grants ")]), i > 0 ? _c('strong', [_vm._v("additional ")]) : _vm._e(), _vm._v("access to " + _vm._s(tierLevel.permissions.length) + " Permissions, " + _vm._s(tierLevel.assetDimensions.length) + " AssetDimensions and " + _vm._s(tierLevel.widgetTypes.length) + " WidgetsTypes ")])]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }