var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CreateProductWizardMolecule', [_c('FormWizard', {
    ref: "wizard",
    attrs: {
      "shape": "circle",
      "color": _vm.theme.colors.primary,
      "start-index": _vm.startIndex,
      "title": '',
      "subtitle": '',
      "stepSize": 'xs',
      "validate-on-back": true
    },
    on: {
      "update:startIndex": function updateStartIndex($event) {
        _vm.startIndex = $event;
      },
      "update:start-index": function updateStartIndex($event) {
        _vm.startIndex = $event;
      },
      "on-complete": _vm.onComplete
    },
    scopedSlots: _vm._u([{
      key: "prev",
      fn: function fn() {
        return [_c('IconButtonAtom', {
          attrs: {
            "disabled": !_vm.canGoBack
          }
        }, [_vm._v(" Back ")])];
      },
      proxy: true
    }, {
      key: "next",
      fn: function fn() {
        return [_c('IconButtonAtom', {
          attrs: {
            "disabled": !_vm.canGoNext
          }
        }, [_vm._v(" Next ")])];
      },
      proxy: true
    }, {
      key: "finish",
      fn: function fn() {
        return [_c('IconButtonAtom', {
          attrs: {
            "disabled": !_vm.canFinish
          }
        }, [_vm._v(" Finish ")])];
      },
      proxy: true
    }])
  }, [_c('TabContent', [_c('CreateProductFirstStepMolecule', {
    attrs: {
      "validations": _vm.$v,
      "productTypes": _vm.productTypes
    }
  }, [_c('TranslationEditorMolecule', {
    attrs: {
      "title": 'ShortDescription',
      "availableLanguages": _vm.availableLanguages,
      "activeLanguageId": _vm.activeLanguageId,
      "translatedTexts": _vm.product.shortDescription.localTranslatedTexts
    },
    on: {
      "set-active-language-id": function setActiveLanguageId($event) {
        _vm.activeLanguageId = $event;
      },
      "set-translated-text": function setTranslatedText($event) {
        return _vm.setTranslatedText($event, 'shortDescription');
      }
    }
  }), _c('TranslationEditorMolecule', {
    attrs: {
      "title": 'Description',
      "availableLanguages": _vm.availableLanguages,
      "activeLanguageId": _vm.activeLanguageId,
      "translatedTexts": _vm.product.description.localTranslatedTexts
    },
    on: {
      "set-active-language-id": function setActiveLanguageId($event) {
        _vm.activeLanguageId = $event;
      },
      "set-translated-text": function setTranslatedText($event) {
        return _vm.setTranslatedText($event, 'description');
      }
    }
  })], 1)], 1), _c('TabContent', [_c('ProductTierLevelsMolecule', {
    attrs: {
      "loading": false,
      "availableLanguages": _vm.availableLanguages,
      "activeLanguageId": _vm.activeLanguageId,
      "productType": _vm.product.type,
      "tierLevels": _vm.product.tierLevels,
      "canAddNewTierLevel": true,
      "canSaveTierLevels": false,
      "addablePermissions": _vm.permissions,
      "addableAssetDimensions": _vm.assetDimensions,
      "addableWidgetTypes": _vm.widgetTypes,
      "addableBundleRelations": _vm.addableBundleRelations,
      "addableBundleRelationsLoading": _vm.addableBundleRelationsLoading
    },
    on: {
      "set-active-language-id": function setActiveLanguageId($event) {
        _vm.activeLanguageId = $event;
      },
      "set-translated-text": _vm.setTierLevelTranslatedText,
      "add-new-tier-level": _vm.addNewTierLevel,
      "add-relation": _vm.addRelation,
      "remove-relation": _vm.removeRelation,
      "remove-tier-level": _vm.removeTierLevel,
      "add-bundle-relation": _vm.addBundleRelation,
      "remove-bundle-relation": _vm.removeBundleRelation,
      "bundle-search-change": _vm.bundleSearchSearchChange,
      "bundle-dropdown-opened": _vm.bundleDropdownOpened,
      "bundle-dropdown-closed": _vm.bundleDropdownClosed
    }
  })], 1), _c('TabContent', [_c('CreateProductThirdStepMolecule', {
    attrs: {
      "product": _vm.product
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }