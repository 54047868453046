var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return this.$apollo.loading ? _c('TalpaLoaderWrapper', {
    attrs: {
      "transparent": true
    }
  }) : _vm.error ? _c('ErrorAtom', {
    attrs: {
      "error": _vm.error
    }
  }) : _vm.availableLanguages.length > 0 ? _c('CreateProductWizardOrganism', {
    attrs: {
      "availableLanguages": _vm.availableLanguages
    }
  }) : _c('ErrorAtom', {
    attrs: {
      "error": new _vm.Error("no languages defined")
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }