var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CreateProductWizardMolecule', [_c('PanelMolecule', [_c('span', {
    staticClass: "title",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_vm._v("Create Product")]), _vm._t("default", null, {
    "slot": "main"
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }