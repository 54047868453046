<template>
  <CreateProductWizardMolecule>
    <FormWizard
      ref="wizard"
      shape="circle"
      :color="theme.colors.primary"
      :start-index.sync="startIndex"
      :title="''"
      :subtitle="''"
      :stepSize="'xs'"
      :validate-on-back="true"
      @on-complete="onComplete"
    >
      <TabContent>
        <CreateProductFirstStepMolecule :validations="$v" :productTypes="productTypes">
          <TranslationEditorMolecule
            :title="'ShortDescription'"
            :availableLanguages="availableLanguages"
            :activeLanguageId="activeLanguageId"
            :translatedTexts="product.shortDescription.localTranslatedTexts"
            @set-active-language-id="activeLanguageId = $event"
            @set-translated-text="setTranslatedText($event, 'shortDescription')"
          />
          <TranslationEditorMolecule
            :title="'Description'"
            :availableLanguages="availableLanguages"
            :activeLanguageId="activeLanguageId"
            :translatedTexts="product.description.localTranslatedTexts"
            @set-active-language-id="activeLanguageId = $event"
            @set-translated-text="setTranslatedText($event, 'description')"
          />
        </CreateProductFirstStepMolecule>
      </TabContent>
      <TabContent>
        <ProductTierLevelsMolecule
          :loading="false"
          :availableLanguages="availableLanguages"
          :activeLanguageId="activeLanguageId"
          :productType="product.type"
          :tierLevels="product.tierLevels"
          :canAddNewTierLevel="true"
          :canSaveTierLevels="false"
          :addablePermissions="permissions"
          :addableAssetDimensions="assetDimensions"
          :addableWidgetTypes="widgetTypes"
          :addableBundleRelations="addableBundleRelations"
          :addableBundleRelationsLoading="addableBundleRelationsLoading"
          @set-active-language-id="activeLanguageId = $event"
          @set-translated-text="setTierLevelTranslatedText"
          @add-new-tier-level="addNewTierLevel"
          @add-relation="addRelation"
          @remove-relation="removeRelation"
          @remove-tier-level="removeTierLevel"
          @add-bundle-relation="addBundleRelation"
          @remove-bundle-relation="removeBundleRelation"
          @bundle-search-change="bundleSearchSearchChange"
          @bundle-dropdown-opened="bundleDropdownOpened"
          @bundle-dropdown-closed="bundleDropdownClosed"
        />
      </TabContent>
      <TabContent>
        <CreateProductThirdStepMolecule :product="product" />
      </TabContent>
      <template #prev>
        <IconButtonAtom :disabled="!canGoBack"> Back </IconButtonAtom>
      </template>
      <template #next>
        <IconButtonAtom :disabled="!canGoNext"> Next </IconButtonAtom>
      </template>
      <template #finish>
        <IconButtonAtom :disabled="!canFinish"> Finish </IconButtonAtom>
      </template>
    </FormWizard>
  </CreateProductWizardMolecule>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { required, requiredIf, requiredUnless, minLength } from 'vuelidate/lib/validators'
import { v4 as uuidv4 } from 'uuid'
import { debounce } from 'vue-debounce'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import { FlashMessages } from '@common/singletons'

import IconButtonAtom from '@/components/Atomic/Atoms/IconButtonAtom'
import CreateProductWizardMolecule from '../Molecules/CreateProductWizardMolecule'
import CreateProductFirstStepMolecule from '../Molecules/CreateProductFirstStepMolecule'
import ProductTierLevelsMolecule from '../Molecules/ProductTierLevelsMolecule'
import TranslationEditorMolecule from '../Molecules/TranslationEditorMolecule'
import CreateProductThirdStepMolecule from '../Molecules/CreateProductThirdStepMolecule'

import PRODUCT_TYPES_QUERY from '#/graphql/marketplace/productTypesQuery.gql'
import ASSET_DIMENSIONS_QUERY from '#/graphql/assetDimensions/assetDimensionsBasic.gql'
import WIDGET_TYPES_QUERY from '#/graphql/widgetTypes/widgetTypes.gql'
import PERMISSIONS_BASIC from '#/graphql/permissions/permissionsBasic.gql'
import PRODUCT_TIER_LEVELS_ADDABLE_QUERY from '#/graphql/marketplace/productTierLevelsAddableQuery.gql'
import CREATE_ONE_I_PRODUCT_MUTATION from '#/graphql/marketplace/createOneIProductMutation.gql'
import CREATE_ONE_TRANSLATED_TEXT_MUTATION from '#/graphql/marketplace/createOneTranslatedTextMutation.gql'

export default {
  inject: ['theme'],
  props: {
    availableLanguages: {
      type: Array,
      required: true,
    },
  },
  components: {
    FormWizard,
    TabContent,
    CreateProductWizardMolecule,
    CreateProductFirstStepMolecule,
    IconButtonAtom,
    ProductTierLevelsMolecule,
    TranslationEditorMolecule,
    CreateProductThirdStepMolecule,
  },
  data() {
    return {
      isCreating: false,
      startIndex: 0,
      productTypes: [],
      activeLanguageId: 'EN',
      permissions: [],
      assetDimensions: [],
      widgetTypes: [],
      addableBundleRelations: [],
      addableBundleRelationsLoading: false,
      product: {
        name: '',
        type: 'APP',
        hasDarkIconOnly: true,
        iconDarkImageId: undefined,
        iconLightImageId: undefined,
        entryPoint: '',
        tierLevels: [],
        shortDescription: {
          localTranslatedTexts: this.availableLanguages.map(language => ({
            id: uuidv4(),
            text: '',
            language,
            isNew: true,
            isDirty: true,
            canSave: false,
            isCompleted: false,
          })),
        },
        description: {
          localTranslatedTexts: this.availableLanguages.map(language => ({
            id: uuidv4(),
            text: '',
            language,
            isNew: true,
            isDirty: true,
            canSave: false,
            isCompleted: false,
          })),
        },
      },
    }
  },
  validations() {
    return {
      product: {
        name: {
          required,
        },
        type: {
          required,
        },
        hasDarkIconOnly: {
          required,
        },
        iconDarkImageId: {
          required,
        },
        entryPoint: {
          required: requiredIf(product => product.type === 'APP'),
        },
        iconLightImageId: {
          required: requiredUnless(product => product.hasDarkIconOnly),
        },
        shortDescription: {
          required,
          localTranslatedTexts: {
            required,
            minLength: minLength(this.availableLanguages?.length),
            $each: {
              isCompleted: {
                mustBeCompleted: value => value,
              },
            },
          },
        },
        description: {
          required,
          localTranslatedTexts: {
            required,
            minLength: minLength(this.availableLanguages?.length),
            $each: {
              isCompleted: {
                mustBeCompleted: value => value,
              },
            },
          },
        },
        tierLevels: {
          required,
          minLength: minLength(1),
          $each: {
            localTranslatedTexts: {
              required,
              minLength: minLength(this.availableLanguages?.length),
              $each: {
                isCompleted: {
                  mustBeCompleted: value => value,
                },
              },
            },
          },
        },
      },
      stepOne: [
        'product.name',
        'product.type',
        'product.hasDarkIconOnly',
        'product.iconDarkImageId',
        'product.iconLightImageId',
        'product.shortDescription',
        'product.description',
      ],
      stepTwo: ['product.tierLevels'],
    }
  },
  created() {
    this.searchBundleRelations = debounce(params => {
      this.searchBundleRelationsDebounced(params)
    }, 150)
  },
  computed: {
    currentStep() {
      return ['stepOne', 'stepTwo', 'stepThree'][this.startIndex]
    },
    currentStepIsValid() {
      return !this.$v[this.currentStep].$invalid
    },
    canGoBack() {
      return true
    },
    canGoNext() {
      return !(this.$v[this.currentStep]?.$invalid ?? true)
    },
    canFinish() {
      return !this.$v.$invalid
    },
  },
  methods: {
    async onComplete() {
      this.isCreating = true
      let bundleRelationKey
      const variables = {
        data: {
          type: this.product.type,
          name: this.product.name,
          hasDarkIconOnly: this.product.hasDarkIconOnly,
          iconDarkImageId: this.product.iconDarkImageId,
          iconLightImageId: this.product.hasDarkIconOnly ? undefined : this.product.iconLightImageId,
        },
      }
      if (this.product.type === 'APP') {
        bundleRelationKey = 'bundledBy'
        variables.data.appDetail = {
          create: {
            entryPoint: this.product.entryPoint,
          },
        }
      } else if (this.product.type === 'BUNDLE') {
        bundleRelationKey = 'bundles'
        variables.data.bundleDetail = {
          create: {},
        }
      } else {
        throw new Error(`unhandled product.type '${this.product.type}' in onComplete`)
      }
      variables.data.tierLevels = {
        create: this.product.tierLevels.map(m => ({
          tierLevel: m.tierLevel,
          permissionIds: {
            set: m.permissions.map(up => up.id),
          },
          assetDimensionIds: {
            set: m.assetDimensions.map(ad => ad.id),
          },
          widgetTypeIds: {
            set: m.widgetTypes.map(wt => wt.id),
          },
          [bundleRelationKey]: {
            connect: m[bundleRelationKey].map(br => ({
              id: br.id,
            })),
          },
        })),
      }
      try {
        const res = await this.$apollo.mutate({
          mutation: CREATE_ONE_I_PRODUCT_MUTATION,
          variables,
        })
        const translatedTexts = [
          ...this.product.shortDescription.localTranslatedTexts.map(m => ({
            text: m.text,
            language: m.language,
            translatableId: res?.data?.createOneIProduct.id,
            translatableType: 'Product',
            translatedField: 'shortDescription',
          })),
          ...this.product.description.localTranslatedTexts.map(m => ({
            text: m.text,
            language: m.language,
            translatableId: res?.data?.createOneIProduct.id,
            translatableType: 'Product',
            translatedField: 'description',
          })),
          ...this.product.tierLevels.reduce((acc, localTierLevel) => {
            acc.push(
              ...localTierLevel.localTranslatedTexts.map(m => ({
                text: m.text,
                language: m.language,
                translatableId: res?.data?.createOneIProduct.tierLevels?.find(f => f.tierLevel === localTierLevel.tierLevel)?.id,
                translatableType: 'ProductTierLevel',
                translatedField: 'description',
              })),
            )
            return acc
          }, []),
        ]

        for await (const translatedText of translatedTexts) {
          await this.$apollo.mutate({
            mutation: CREATE_ONE_TRANSLATED_TEXT_MUTATION,
            variables: {
              data: {
                ...translatedText,
              },
            },
          })
        }
        this.$router.push({
          name: 'EditProduct',
          params: {
            productId: res?.data?.createOneIProduct.id,
          },
        })
        FlashMessages.$emit('success', `Successfully created new product '${this.product.name}'`, {
          timeout: 1500,
        })
      } catch (err) {
        FlashMessages.$emit('error', err)
        throw err
      } finally {
        this.isCreating = false
      }
    },
    setTranslatedText($event, fieldName) {
      const translatedText = this.product[fieldName]?.localTranslatedTexts.find(f => f.id === $event.id)
      if (translatedText) {
        translatedText.text = $event.text
        translatedText.isCompleted = $event.text?.trim()?.length > 0
      }
    },
    setTierLevelTranslatedText($event, tierLevelId) {
      const { localTierLevel } = this.getLocalTierLevelOrThrow(tierLevelId)
      const translatedText = localTierLevel.localTranslatedTexts.find(f => f.id === $event.id)
      if (translatedText) {
        translatedText.text = $event.text
        translatedText.isCompleted = $event.text?.trim()?.length > 0
      } else {
        throw new Error(`could not find translated text '${$event.id}' in tierLevel '${tierLevelId}'`)
      }
    },
    addNewTierLevel() {
      this.product = {
        ...this.product,
        tierLevels: this.product.tierLevels.concat({
          id: uuidv4(),
          isNew: true,
          hasSaveTranslatedTexts: false,
          tierLevel: this.product.tierLevels.length + 1,
          canSaveAllTranslatedTexts: false,
          canRestoreAllTranslatedTexts: false,
          permissions: [],
          assetDimensions: [],
          widgetTypes: [],
          bundles: [],
          bundledBy: [],
          localTranslatedTexts: this.availableLanguages.map(language => ({
            id: uuidv4(),
            text: '',
            language,
            isNew: true,
            isDirty: true,
            canSave: false,
            isCompleted: false,
          })),
        }),
      }
    },
    async addRelation(relation, tierLevelId) {
      const { localTierLevel } = this.getLocalTierLevelOrThrow(tierLevelId)
      if (!relation?.__typename) {
        throw new Error(`missing '__typename' in relation`)
      }
      if (relation.__typename === 'Permission') {
        localTierLevel.permissions.push(relation)
      } else if (relation.__typename === 'AssetDimension') {
        localTierLevel.assetDimensions.push(relation)
      } else if (relation.__typename === 'WidgetType') {
        localTierLevel.widgetTypes.push(relation)
      } else {
        throw new Error(`unhandled __typename '${relation.__typename}'`)
      }
    },
    async removeRelation(relation, tierLevelId) {
      const { localTierLevel } = this.getLocalTierLevelOrThrow(tierLevelId)
      if (!relation?.__typename) {
        throw new Error(`missing '__typename' in relation`)
      }
      const compatibleTypes = ['Permission', 'AssetDimension', 'WidgetType']
      if (!compatibleTypes.includes(relation.__typename)) {
        throw new Error(`__typename '${relation.__typename}' is not compatible`)
      }
      if (relation.__typename === 'Permission') {
        localTierLevel.permissions = localTierLevel.permissions.filter(f => f.id !== relation.id)
      } else if (relation.__typename === 'AssetDimension') {
        localTierLevel.assetDimensions = localTierLevel.assetDimensions.filter(f => f.id !== relation.id)
      } else if (relation.__typename === 'WidgetType') {
        localTierLevel.widgetTypes = localTierLevel.widgetTypes.filter(f => f.id !== relation.id)
      } else {
        throw new Error(`unhandled __typename '${relation.__typename}'`)
      }
    },
    removeTierLevel(id) {
      this.product.tierLevels = this.product.tierLevels.filter(f => f.id !== id)
    },
    async addBundleRelation(relation, tierLevelId) {
      const { localTierLevel } = this.getLocalTierLevelOrThrow(tierLevelId)
      let relationKey = null
      if (this.product?.type === 'APP') {
        relationKey = 'bundledBy'
      } else if (this.product?.type === 'BUNDLE') {
        relationKey = 'bundles'
      } else {
        throw new Error(`unhandled product type '${this.product?.type}' in addBundleRelation`)
      }
      localTierLevel?.[relationKey].push(relation)
    },
    async removeBundleRelation(relation, tierLevelId) {
      const { localTierLevel } = this.getLocalTierLevelOrThrow(tierLevelId)
      let relationKey = null
      if (this.product?.type === 'APP') {
        relationKey = 'bundledBy'
      } else if (this.product?.type === 'BUNDLE') {
        relationKey = 'bundles'
      } else {
        throw new Error(`unhandled product type '${this.product?.type}' in removeBundleRelation`)
      }
      localTierLevel[relationKey] = localTierLevel?.[relationKey].filter(f => f.id !== relation.id)
    },
    getLocalTierLevelOrThrow(tierLevelId) {
      const idx = this.product.tierLevels.findIndex(f => f.id === tierLevelId)
      if (idx === -1) {
        throw new Error(`did not find '${tierLevelId}' in localTierLevels`)
      }
      return { idx, localTierLevel: this.product.tierLevels[idx] }
    },
    async bundleSearchSearchChange(searchQuery, tierLevelId) {
      await this.searchBundleRelations({ searchQuery, tierLevelId })
    },
    async bundleDropdownOpened(tierLevelId) {
      await this.searchBundleRelations({ tierLevelId })
    },
    bundleDropdownClosed() {
      this.addableBundleRelations = []
    },
    async searchBundleRelationsDebounced({ searchQuery, tierLevelId }) {
      this.addableBundleRelationsLoading = true
      try {
        const { localTierLevel } = this.getLocalTierLevelOrThrow(tierLevelId)
        // filter out tierLevels that are already taken by this
        // tierLevel or tierLevels with lower level
        const ignoredTierLevelIds = this.product.tierLevels
          .filter(f => f.tierLevel <= localTierLevel.tierLevel)
          .flatMap(fm => [...fm.bundles.map(m => m.id), ...fm.bundledBy.map(m => m.id)])
        const ignoredProductIds = [...localTierLevel.bundles.map(m => m.product.id), ...localTierLevel.bundledBy.map(m => m.product.id)]
        const variables = {
          where: {
            id: {
              notIn: [...ignoredTierLevelIds],
            },
            product: {
              isNot: {
                OR: [
                  {
                    type: {
                      equals: this.product?.type,
                    },
                  },
                  {
                    id: {
                      in: [...ignoredProductIds],
                    },
                  },
                ],
              },
            },
          },
          take: 10,
        }
        if (this.productId) {
          variables.where.product.isNot.OR[1].id.in.push(this.productId)
        }
        if (searchQuery) {
          variables.where.product.is = {
            name: {
              contains: searchQuery,
              mode: 'insensitive',
            },
          }
        }
        const res = await this.$apollo.query({
          query: PRODUCT_TIER_LEVELS_ADDABLE_QUERY,
          variables,
        })
        this.addableBundleRelations = res.data.productTierLevels.map(m => ({
          id: m.id,
          label: `${m.product?.name} - Tier level ${m.tierLevel}`,
          // make tierlevel, product and __typename available in addables
          // so they can be pushed directly to bundles/bundledBy arrays when added
          tierLevel: m.tierLevel,
          product: m.product,
          __typename: m.__typename,
        }))
      } catch (err) {
        throw new Error(err)
      } finally {
        this.addableBundleRelationsLoading = false
      }
    },
  },
  apollo: {
    productTypes: {
      query: PRODUCT_TYPES_QUERY,
      result({ data }) {
        this.productTypes = data.productTypes.enumValues
      },
    },
    assetDimensions: {
      query: ASSET_DIMENSIONS_QUERY,
    },
    widgetTypes: {
      query: WIDGET_TYPES_QUERY,
    },
    permissions: {
      query: PERMISSIONS_BASIC,
    },
    // dashboards: {
    //   query: DASHBOARDS_LIST,
    // },
  },
}
</script>
