<template>
  <MultiselectFieldMolecule>
    <LabelAtom class="label" :label="label" :isMuted="false" />
    <Multiselect
      :value="value"
      :allow-empty="false"
      :options="options"
      :show-labels="false"
      :searchable="false"
      @select="$emit('update:value', $event)"
    />
  </MultiselectFieldMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import Multiselect from 'vue-multiselect'

import LabelAtom from '@/components/Atomic/Atoms/LabelAtom'

const MultiselectFieldMolecule = styled('div')`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-gap: 0.5rem;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.archonBlackTransparent};
  background: ${({ theme }) => theme.colors.archonBlackTransparent};
  border-radius: 0.5rem;
  > .label {
    margin: 0.25rem;
  }
  > .multiselect {
    > .multiselect__tags {
      border-radius: 0.25rem;
      min-height: 1.5rem;
      margin: 0.25rem;
      padding: 2px 40px 2px 0;
      > .multiselect__single {
        margin-top: 4px;
        margin-bottom: 4px;
      }
      > .multiselect__input {
        margin-top: 4px;
        margin-bottom: 4px;
      }
    }
  }
`

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  components: {
    MultiselectFieldMolecule,
    LabelAtom,
    Multiselect,
  },
}
</script>
