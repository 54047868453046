<template>
  <CreateProductThirdStepMoleculeStyled>
    <strong>You will create the following product:</strong>
    <div class="details">
      <div class="type">Type: {{ product.type }}</div>
      <div class="name">Name: {{ product.name }}</div>
      <div v-if="product.type === 'APP'" class="entry-point">Entry point: {{ product.entryPoint }}</div>
    </div>
    <strong>with the following tier levels:</strong>
    <div class="tier-level" v-for="(tierLevel, i) in product.tierLevels" :key="tierLevel.id">
      <div>
        <strong>Tier level {{ tierLevel.tierLevel }}</strong>
      </div>
      <div>
        <span>that grants </span><strong v-if="i > 0">additional </strong>access to {{ tierLevel.permissions.length }} Permissions,
        {{ tierLevel.assetDimensions.length }} AssetDimensions and {{ tierLevel.widgetTypes.length }} WidgetsTypes
      </div>
    </div>
  </CreateProductThirdStepMoleculeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const CreateProductThirdStepMoleculeStyled = styled('div')`
  padding: 3rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 2rem;
`

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  components: {
    CreateProductThirdStepMoleculeStyled,
  },
}
</script>
