var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MultiselectFieldMolecule', [_c('LabelAtom', {
    staticClass: "label",
    attrs: {
      "label": _vm.label,
      "isMuted": false
    }
  }), _c('Multiselect', {
    attrs: {
      "value": _vm.value,
      "allow-empty": false,
      "options": _vm.options,
      "show-labels": false,
      "searchable": false
    },
    on: {
      "select": function select($event) {
        return _vm.$emit('update:value', $event);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }